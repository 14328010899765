export default {
  // 试用中心-首页
  '1-1': 'free-trial-index',
  // 试用中心-个人中心页
  '1-2': 'free-trial-person-center',
  // 试用中心-提交报告页
  '1-3': 'free-trial-post-report',
  // 试用中心-报告详情页
  '1-4': 'free-trial-report-detail',
  // 列表页
  '1-5': 'product-list',
  // 详情页
  '1-6': 'product-detail',
  // 相似商品结果页
  '1-7': 'similar-product',
  // 购物车页
  '1-8': 'cart',
  // 闪购页
  '1-9': 'flash-sale',
  // 收藏夹
  '1-10': 'wishlist',
  // 下单页
  '1-11': 'place-order',
  // 支付成功页
  '1-12': 'pay-success',
  // category / sidecat
  '1-14': 'category',
  // 首页
  '1-15': 'config-index',
  // 个人中心
  '1-16': 'dashboard',
  // 物流轨迹
  '1-17': 'track',
  // Me页面地址
  '1-18': 'address',
  // 订单详情
  '1-19': 'order-detail',
  // 订单列表
  '1-20': 'order-list',
  // 专题活动页面
  '1-21': 'config-activity',
  // checkout首单地址
  '1-23': 'first-address',
  // robot机器人
  '1-24': 'robot',
  // 个人中心
  '1-25': 'user',
  // 老带新 share&earn
  '1-26': 'shareandearn',
  // 直播页
  '1-27': 'live',
  // 礼品卡订单详情
  '1-28': 'giftcard-detail',
  // 礼品卡订单列表
  '1-29': 'giftcard-list',
  // 退货页
  '1-30': 'order-return',
  // 订单评论
  '1-31': 'order-review',
  // faq
  '1-32': 'faq',
  // 订单回收站
  '1-33': 'order-recycle',
  // 数据主体权利中心
  '1-35': 'privacy-request',
  // 卡支付页面
  '1-34': 'payment',
  // 买家秀
  '1-36': 'buyer-show',
  // notification
  '1-37': 'notification',
  // inner remind
  '1-38': 'remind',
  // payCod
  '1-39': 'payCod',
  // article
  '1-40': 'article',
  // 海关拦截异常页
  '1-41': 'order-customs',
  // 发票中心
  '1-42': 'order-invoices',
  // 合单承接页
  '1-43': 'order-combined',
  // 订单拒收
  '1-46': 'order-reject-reason',
  // 帐号删除
  '1-47': 'deactivate-account',
  // 评论中心
  '1-48': 'review-center',
  // sheinx设计师列表
  '1-49': 'designer-list',
  // romwevip
  '1-50': 'romwevip',
  // 风控审单上传页
  '1-51': 'verification',
  // 分享赚活动
  '1-52': 'friendship-coupon',
  // blik
  '1-53': 'blik-code-way',
  // 店铺评论页
  '1-54': 'store-comment',
  // 礼品卡下单页
  '1-55': 'giftcard-checkout',
  // token卡绑定
  '1-56': 'user-token-bind',
  // 短信退订页
  '1-57': 'sms_unsubscribe',
  // 账号安全页
  '1-58': 'user',
  // 邮箱解绑
  '1-59': 'email_unbind',
  // 手机号换绑
  '1-60': 'identity_verification',
  // 支付结果未知状态页
  '1-61': 'pay-pending',
  // 回归用户券包弹窗
  '1-62': 'coupon-revisit',
  // 店铺品类推荐页
  '1-63': 'store-page',
  // 收藏店铺列表落地页
  '1-64': 'user',
  // 营销落地页
  '1-65': 'advertising',
  // 评论详情页
  '1-66': 'review-already',
  // h5榜单
  '1-67': 'rank-products',
  // 礼品卡详情页
  '1-68': 'giftcard-home',
  // 不贵频道
  '1-69': 'thrifty-find',
  // 订单换货列表(新)
  '1-70': 'order-exchange',
  // 品牌馆（品牌频道）
  '1-71': 'brand-zone',
  // 折扣频道
  '1-72': 'discount-channel',
  // 个人中心-news
  '1-73': 'user-news',
  // 趋势频道
  '1-74': 'top-trend',
  // 趋势落地页
  '1-75': 'trend-landing',
  // 趋势搭配页
  '1-76': 'trend-outfits',
  // 信息流： 用户行为落地页
  '1-77': 'picked_products',
  // 公共组件-地址选择
  '2-1': 'address-edit.js',
  // 资源位上报
  '2-2': 'resource.js',
  // 公共商品组件
  '2-3': 'product-item.js',
  // 推荐-自动化埋点组件
  '2-4': 'auto-rcmd.js',
  // 地址组件
  '2-5': 'address-vue.js',
  // 商详底部推荐组件
  '2-6': 'recommend.js',
  // 专题组件
  '2-7': 'act-component.js',
  // 登录组件
  '2-8': 'login.js',
  // 头部
  '2-9': 'header.js',
  // ccc组件
  '2-10': 'ccc-component.js',
  // 券包弹窗
  '2-11': 'coupon-package.js',
  // ip跳转弹窗
  '2-12': 'ip-jump.js',
  // 心愿单弹窗组件
  '2-13': 'popup-group.js',
  // 筛选组件
  '2-14': 'filter.js',
  // 底部tab组件
  '2-15': 'footer.js',
  // 邮箱验证
  '2-16': 'email-validate.js',
  // 新筛选组件
  '2-17': 'filter-new.js',
  // 首页消息提醒弹框
  '2-18': 'message-dialog.js',
  // 现金支付回退倒计时组件
  '2-19': 'back-tip.js',
  // 快速加车 quick add
  '2-20': 'quick-add.js',
  // 底部shein-info
  '2-21': 'shein-info.js',
  // CCC 重构组件
  '2-22': 'ccc.js',
  // 评论组件相关
  '2-23': 'comment.js',
  // 分享相关
  '2-24': 'share-common.js',
  // 一键购(含支付成功弹窗)
  '2-25': 'one-click-pay',
  // 大卡片 / MiniDetail
  '2-26': 'mini-detail.js',
  // 店铺tab
  '2-27': 'store-tab.js',
  // 未满十八岁弹窗
  '2-28': 'age-limit-dialog.js',
  // 地址推荐弹窗
  '2-29': 'recommended-address-dialog.js',
  // 地址确认弹窗
  '2-30': 'confirm-address-dialog.js',
  // 支付通用埋点
  '2-31': 'payment-common.js',
  // fpx支付方式银行升级提醒弹窗
  '2-32': 'fpx-tips-dialog.js',
  // 定制商品详情弹窗 -- 交易链路
  '2-33': 'customDetailDialog.js',
  // 新样式券包弹窗埋点
  '2-34': 'coupon-package-v2.js',
  // 未支付订单埋点
  '2-35': 'unpaid-component.js',
  // 首页队列弹窗
  '2-36': 'trackingEvents.js',
  // 闪购商品组件埋点
  '2-37': 'flashsale-product-item.js',
  // 券规范组件埋点
  '2-38': 'common-coupon.js',
  // 店铺闪购商品组件埋点
  '2-39': 'store-flashsale-product-item.js',
  // cod新版免验证弹窗埋点
  '2-40': 'cod-verify-free-popup.js',
  // 卡支付前置弹窗埋点
  '2-41': 'pre-pay.js',
  // 下单 Saver 组件埋点
  '2-42': 'saver-product-package.js',
  // webpush订阅通知功能相关埋点
  '2-43': 'webpush.js',
  // cookieBanner隐私弹窗功能相关埋点
  '2-44': 'privacy-popup.js',
  // 趋势搜索底部推荐
  '2-45': 'trend-search-recommend.js'
}
